.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.site-layout-content {
	background: #fff;
	padding: 24px;
	margin-top: 20px;
	min-height: 400px;
	margin: auto;
	text-align: center;
}
.logo {
	width: 120px;
	height: 31px;
	background: rgba(255, 255, 255, 0.2);
	margin: 16px 24px 16px 0;
}

#mainheader {
	width: 100%;
	text-align: center;
	background-color: #444;
	margin: 0px;
	padding: 0px;
	border: 0px solid red;
}

#collegeScoreBoard {
	padding: 10px;
	margin: auto;
	margin-top: 10px;
	font-size: 0.9em;
	display: inline-block;
	text-align: center;
	max-width: 550px;
}

#collegeScoreBoard .btGame {
	margin: 2px;
	display: inline-block;
	border: 1px solid #ccc;
}

#collegeScoreBoard .btGameHdr {
	background-color: #00a7e3;
	color: white;
	padding-right: 2px;
	padding-left: 2px;
}

#collegeScoreBoard a {
	color: #000000;
	text-decoration: none;
}

.border {
	border: 1px solid blue;
}

@media only screen and (max-width: 550px) {
	.ant-layout-content {
		padding: 0px;
	}
}
